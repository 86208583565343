<template>
    <div class="w-full h-full">
        <transition name="fade">
            <template v-if="isInitializing">
                <div class="w-full h-full flex items-center justify-center">
                    <v-loader />
                </div>
            </template>

            <template v-else>
                <template v-if="modelForm.deleted_at">
                    <v-alert color="danger">
                        <template v-slot:name>
                            <v-text>
                                This resource has been deleted.
                            </v-text>
                        </template>
                    </v-alert>
                </template>

                <slot
                    :model="model"
                    :modelForm="modelForm"
                    :refreshModel="refreshModel"
                />
            </template>
        </transition>
    </div>
</template>

<script>
export default {
    name: "ModelForm",
    inheritAttrs: false,
    props: {
        module: {
            type: String,
            required: false,
            default: () => "",
        },
        id: {
            type: [String, Number],
            required: true,
            default: () => "",
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            error: null,
            model: {},
            modelForm: {},
        };
    },
    computed: {
        fetchQuery() {
            return {
                id: this.id,
                payload: this.query,
            };
        },
    },
    watch: {
        fetchQuery: {
            handler(value) {
                this.init(value);
            },
            deep: true,
            immediate: true,
        },
        model: {
            handler(value) {
                this.modelForm = value;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        init(payload) {
            return new Promise((resolve, reject) => {
                this.toggleLoading();

                const handleSuccess = (response) => {
                    this.error = null;
                    this.model = response.data;
                    resolve(response);
                };

                this.$store.dispatch(`${this.module}/fetchOneItem`, payload)
                    .then(handleSuccess)
                    .catch((error) => reject(error))
                    .finally(() => {
                        if (this.isInitializing) {
                            this.toggleInitialize();
                        }

                        this.toggleLoading();
                    });
            });
        },
        async refreshModel() {
            this.toggleLoading();

            await this.$store.dispatch(`${this.module}/fetchOneItem`, this.fetchQuery)
                .then((response) => {
                    this.model = response.data;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
    },
};
</script>
